import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import IntroCTA from "../components/intro-cta";
import ClientsLogotypes from "../components/clients-logotypes";

const IntroSection = () => {
    const data = useStaticQuery(graphql`
        query {
            introBG: file(relativePath: { eq: "backgrounds/cdwv-bg-intro-alt.svg" }) {
                publicURL
            }
        }
    `);

    let serviceSectionStyle = {
        backgroundImage: `url(${data.introBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid" style={serviceSectionStyle}>
            <div className="intro">
                <div className="intro__column">
                    <IntroCTA/>
                </div>
                <div className="intro__column">
                    <ClientsLogotypes/>
                </div>
            </div>
        </section>
    )
}

export default IntroSection