import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import ServiceBox from "../components/service-box";

const ServicesSection = () => {
    const data = useStaticQuery(graphql`
        query {
            servicesBG: file(relativePath: { eq: "backgrounds/cdwv-bg-1.svg" }) {
                publicURL
            }
            
            services: allCodewaveService(filter: {isPublic: {eq: true}}) {
                nodes {
                    name
                    shortDescription
                    slug
                    icon {
                        id
                        extension
                    }
                }
            }
        }
    `);

    let serviceSectionStyle = {
        background: `radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0) 75%, #F9FCFC 100%), url(${data.servicesBG.publicURL})`
    }
    let services = data.services.nodes;
    return <section className="cw-container-fluid" style={serviceSectionStyle}>
        <div className="cw-container content-section services-section">
            <h2 className="section-header section-header--colored">Services</h2>
            <p className="section-description section-description--condensed">
                CodeWave incorporated in 2008. Internet was a different
                place back then. But for the last 12 years we've been
                constantly delivering value to our clients.
            </p>
            <div className="service-boxes-wrapper">
                {
                    services.map(service => <ServiceBox key={service.slug} service={service}/>)
                }
            </div>
        </div>
    </section>
}

export default ServicesSection