import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import CertificatesBadges from "../components/certificates-badges";
import PartnersLogotypes from "../components/partners-logotypes";
import AniLink from "gatsby-plugin-transition-link/AniLink"

const CertificatesSection = () => {
    const data = useStaticQuery(graphql`
        query {
            servicesBG: file(relativePath: { eq: "backgrounds/cdwv-bg-2.svg" }) {
                publicURL
            }
        }
    `);

    let serviceSectionStyle = {
        background: `radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0) 75%, #F9FCFC 100%), url(${data.servicesBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid"  style={serviceSectionStyle}>
            <div className="cw-container content-section">
                <div className="certificates-section">
                    <div className="certificates-section__certificates-row">
                        <div className="certificates-section__info-column">
                            <h2 className="section-header section-header--colored">Certificates</h2>
                            <p className="section-description">
                                We're certified partners of AWS and Microsoft Azure, our SRE's (Site Reliability Engineers) and DevOps specialists make sure that our client's cloud-based infrastructure operates smoothly and cost-effectively.
                            </p>
                            <AniLink duration={.5} fade to="/contact" className="btn">Get in Touch</AniLink>
                        </div>
                        <div className="certificates-section__badges-column">
                            <CertificatesBadges/>
                        </div>
                    </div>
                    <div className="certificates-section__technology-partners-row">
                        <p>We partner with the world technology leaders:</p>
                        <PartnersLogotypes/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CertificatesSection