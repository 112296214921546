import React from "react"
import Layout from "../components/layout"
import IntroSection from "../sections/intro-section"
import TestimonialsSection from "../sections/testimonials-section"
import SEO from "../components/seo"
import CTASection from "../sections/cta-section";
import CertificatesSection from "../sections/certificates-section";
import ServicesSection from "../sections/services-section";
import ClientSuccessStoriesSection from "../sections/client-success-stories-section"

const IndexPage = ({data}) => (
    <Layout>
        <SEO title="Home"/>
        <IntroSection/>
        <ServicesSection/>
        <TestimonialsSection/>
        <ClientSuccessStoriesSection/>
        <CertificatesSection/>
        <CTASection/>
    </Layout>
)

export default IndexPage