import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import SuccessStoryTile from "../components/success-story-tile";

const ClientSuccessStoriesSection = () => {
    const data = useStaticQuery(graphql`
        query {
            successStoreisBG: file(relativePath: { eq: "backgrounds/cdwv-bg-4.svg" }) {
                publicURL
            }
            projects: allCodewaveProject(
                    filter: {
                        isPublic: {eq: true}, 
                        isCaseStudy: {eq: true}, 
                        categories: {elemMatch: {name: {regex: "/featured/i"}}}
                    }, 
                    limit: 3
                ) 
            {
                nodes {
                    name
                    slug
                    shortDescription
                    tags {
                        name
                        slug
                    }
                    projectTileImage {
                        extension
                        id
                    }
                }
            }
        }
    `);

    let successStoriesSectionStyle = {
        background: `radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0) 75%, #F9FCFC 100%), url(${data.successStoreisBG.publicURL})`
    }
    let projects = data.projects.nodes;
    return (
        <section className="cw-container-fluid"  style={successStoriesSectionStyle}>
            <div className="cw-container content-section clients-success-stories-section">
                <h2 className="section-header section-header--colored">Our Clients success stories</h2>
                <p className="section-description section-description--condensed">
                    We believe that transparency and customer engagement on every stage of the project is crucial for project success.
                </p>
                <div className="success-stories">
                    {projects.map( project =>
                        <SuccessStoryTile key={project.slug} project={project}/>
                    )}
                </div>
            </div>
        </section>
    )
}

export default ClientSuccessStoriesSection

