import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CertificatesBadges = () => {
    const data = useStaticQuery(graphql`
        query {
            cert1: file(relativePath: { eq: "certificates/clutch-top-1000.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            cert2: file(relativePath: { eq: "certificates/clutch-top-2018.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            cert3: file(relativePath: { eq: "certificates/clutch-top-2019.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)

    return (
        <div className="certificates-badges">
            <Img className="certificate-badge" fluid={data.cert2.childImageSharp.fluid} />
            <Img className="certificate-badge" fluid={data.cert3.childImageSharp.fluid} />
            <Img className="certificate-badge" fluid={data.cert1.childImageSharp.fluid} />
        </div>
    )
}

export default CertificatesBadges
