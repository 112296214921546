import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"

const IntroCTA = () => (
    <div className="intro-cta">
        <h1 className="intro-cta__header intro-cta__header--colored">Scalable Web Solutions</h1>
        <p className="intro-cta__description">CodeWave is a performance- and scalability-focused web development firm.</p>
        <div className="intro-cta__buttons">
            <AniLink duration={.5} fade to="/contact" className="btn">Get in Touch</AniLink>
        </div>
    </div>
)

export default IntroCTA