import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import RightArrow from "../images/svg/right-arrow.svg"
import imageUrl from "../helpers/flotiqImage";
const ServiceBox = ({service}) => (
    <AniLink duration={.5} fade to={`/service/${service.slug}`} className="service-box">
        <div className="service-box__icon">
            <img alt={service.name} src={imageUrl(service.icon, {width: 60})} />
        </div>
        <div className="service-box__description">
        <h3>{service.name}</h3>
            <div dangerouslySetInnerHTML={{__html: service.shortDescription}}></div>
        </div>
        <div className="service-box__read-more">
            <p className="read-more-text">Read more</p>
            <RightArrow/>
        </div>
    </AniLink>
)

export default ServiceBox